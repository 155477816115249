/* PAGE CONTENT */

/* General */

.page-content .alert {
  margin: 0;
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

/* Upload Dragger */

.page-content .ant-upload-drag {
  border-color: #000 !important;
  background-color: var(--light-grey);
  color: #000;
  border-radius: var(--border-radius);
}

.page-content .ant-upload-drag svg {
  color: var(--primary-color);
}

.page-content .ant-upload-drag .ant-upload-drag-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  gap: 1em;
  font-size: 1.2em;
}

.page-content .ant-upload-wrapper.shrunk .ant-upload-btn .ant-upload-drag-container {
  flex-direction: row;
  height: 150px;
}

.page-content .ant-upload-wrapper.success .ant-upload-drag {
  background-color: #d1e7dd;
  border-color: var(--success-color) !important;
  color: var(--success-color);
}

.page-content .ant-upload-wrapper .ant-upload-drag:hover .upload-icon {
  animation: jumping-animation 1s linear infinite;
}

/* Face Upload */

.page-content .ant-upload-wrapper.face-upload .ant-upload-drag {
  height: auto;
}

.page-content .ant-upload-wrapper.face-upload .ant-upload-btn {
  width: calc((var(--page-width) - 6vw) / 3 - 2px);
  /* page width minus 2 3vw gaps divided into 3 blocks, minus 2 1px borders */
  padding: 1.5vw; 
}

.page-content .ant-upload-wrapper.face-upload .ant-upload-drag-container {
  height: 15vw;
  font-size: 1em;
}

.page-content .ant-upload-wrapper.face-upload .ant-upload-drag-container .profile-image {
  width: 15vw;
  height: 15vw;
  object-fit: cover;
  border-radius: 50%;
}

.page-content .ant-upload-wrapper.pulsing .ant-upload-drag:hover svg {
  animation: pulsing-animation 1s linear infinite;
}

.names-list-group {
  max-height: 220px;
  overflow-y: scroll;
  cursor: all-scroll;
}

.names-list-group .name-list-text {
  min-width: 10vw;
}

.names-list-group .names-list-placeholder {
  background-color: var(--light-pink);
  padding: 10px;
  text-align: center;
  border-radius: var(--border-radius);
}

.names-list-group ul {
  padding: 0;
  margin: 0;
}

.names-list-group .name-list-item {
  padding: 0.5em 1em;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}


.names-list-group .name-list-item:last-child {
  margin-bottom: 0; 
}

.names-list-group .spinner-border {
  color: var(--primary-color);
  display: block;
  margin: auto;
}

.names-list-group .name-list-item .name-list-upload-date {
  margin-left: 20px;
  font-style: italic;
}

/* Page content */

.page-content .sub-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-content .sub-heading-container .text-with-icon .icon svg {
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.page-content .sub-heading-container .text-with-icon .icon:hover {
  color: var(--primary-color);
}

.page-content .sub-heading-container h4,
.page-content h4 {
  margin: 0;
}

.page-content .clips-groups-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.page-content .clips-groups-container .clips-group {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.page-content .clips-groups-container .clips-group .face-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.page-content .clips-groups-container .clips-group h5 {
  margin: 0;
}

/* Faces Upload Container */

.page-content .faces-uploads-container {
  display: flex;
  gap: 3vw;
}

.page-content .faces-container .face-block .submit-spinner {
  border-width: 0.1em;
}

.page-content .faces-container .face-preview {
  cursor: pointer;
}

.page-content .face-submit-btn {
  width: 100%;
  height: var(--form-el-height);
}

.page-content .new-face-btn {
  width: var(--face-size);
  height: var(--face-size);
  font-size: 50px;
  border-radius: 50%;
  pointer-events: all;
  background: transparent;
  border: 5px solid var(--primary-color);
  color: var(--primary-color);
  padding-bottom: 5%;
}

.page-content .new-face-btn:hover {
  background: var(--light-pink);
  border-color: var(--primary-color);
  color: var(--primary-color);
  opacity: 0.7;
}

.page-content .new-face-btn:active {
  opacity: 0.9;
}

/* Image Overlay – used in thumbnail and face containers */

.img-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  gap: 5px;
  background-color: rgba(220, 74, 93, 0.5);
  color: #fff;
  text-align: center;
}

.img-overlay .loading-icon {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
}

.img-overlay .loading-icon > * {
  position: absolute;
}

.img-overlay.PROCESSED .loading-icon > * {
  transition: all 0.5s ease-in-out;
}

.img-overlay.PROCESSED .loading-icon .spinner-border,
.img-overlay.ERROR .loading-icon .spinner-border,
.img-overlay .loading-icon .check-icon,
.img-overlay .loading-icon .error-icon {
  opacity: 0;
}

.img-overlay .loading-icon .spinner-border,
.img-overlay.PROCESSED .loading-icon .check-icon,
.img-overlay.ERROR .loading-icon .error-icon {
  opacity: 1;
}

.img-overlay .loading-description {
  font-size: 0.7em;
}

.img-overlay .delete-icon {
  display: none;
  position: absolute;
  top: -0.55rem;
  right: -0.55rem;
  color: rgba(210, 4, 45);
  background-color: white;
  border-radius: 50%;
  border: 1px white solid;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.img-overlay .delete-icon:hover {
  transform: scale(1.1);
}

.img-overlay.ERROR .delete-icon {
  display: block;
}

/* Thumbanails Container – Template Upload and Raw Footage Upload pages */

.page-content .thumbanails-container {
  display: flex;
  gap: 1vw;
}

.thumbanails-container .thumbanail-block .thumbnail {
  object-fit: cover;
}

.template-preview,
.template-preview .thumbanails-container .thumbanail-block {
  position: relative;
}

.template-preview .thumbanails-container {
  padding: 1em;
  border: 1px dashed var(--primary-color) !important;
  border-radius: var(--border-radius);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  overflow: auto !important;
  animation: fadeIn 1s ease-in;
}

.template-preview .thumbanails-container .thumbanail-block,
.template-preview .thumbanails-container .thumbanail-block .thumbnail,
.template-preview .thumbanails-container .thumbanail-block .img-overlay {
  width: 11vw;
  height: calc(11vw * 9 / 16);
}

.template-preview .thumbanails-container .thumbnail {
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.template-preview .thumbanails-container .thumbnail.enlarge:hover {
  transform: scale(1.1);
}

.template-preview .gradient-effect {
  position: absolute;
  width: calc(var(--page-width) - 2px);
  height: calc((11vw * 9 / 16) + 2em);
  pointer-events: none;
  top: 1px;
  left: 1px;
  border-radius: var(--border-radius);
  background-image: linear-gradient(to right, white 0%, transparent 10%, transparent 85% , white 100%);
}

.footage-upload .thumbanails-container {
  flex-wrap: wrap;
}

.footage-upload .thumbanails-container .thumbanail-block {
  position: relative;
  width: var(--thumbnail-width);
}

.footage-upload .thumbanails-container .thumbanail-block .thumbnail,
.footage-upload .thumbanails-container .thumbanail-block .img-overlay,
.footage-upload .thumbanails-container .thumbanail-block .clip-duration {
  width: var(--thumbnail-width);
  height: calc(var(--thumbnail-width) * 9 / 16);
  border-radius: var(--border-radius);
}

.thumbanails-container .thumbanail-block .file-name {
  width: 100%;
  font-size: 0.8em;
  margin-top: 5px;
  word-wrap: break-word;
  line-height: 1;
}

.thumbanails-container .thumbanail-block .clip-duration {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 0.7em;
  color: #fff;
}

.thumbanails-container .thumbanail-block .clip-duration > * {
  min-width: 50px;
  padding: 1em 0.5em 0.5em 1.5em;
  text-align: center;
  border-top-left-radius: 100%;
  border-bottom-right-radius: var(--border-radius);
  background-color: rgba(0, 0, 0, 0.6);
}

.thumbnail-preview {
  width: 100%;
}

/* Faces Container */

.page-content .faces-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5vw;
}

.page-content .faces-container .face-block {
  text-align: center;
}

.page-content .faces-container .face-block .face-icon,
.page-content .faces-container .face-block .img-overlay {
  width: var(--face-size);
  height: var(--face-size);
  border-radius: 50%;
}

.page-content .faces-container .face-block .face-name {
  width: var(--face-size);
}

.page-content .faces-container .face-block .face-icon {
  object-fit: cover;
  margin-bottom: 0.5em;
}

.page-content .faces-container .req-shots-list {
  padding: 0;
  margin: 0;
  font-size: 0.9em;
}

.page-content .faces-container .req-shots-list li {
  list-style: none;
}

.page-content .faces-container .req-shots-list li.yes{
  color: var(--success-color);
}

.page-content .faces-container .req-shots-list li.no {
  color: var(--error-color);
}

/* Updated Faces Container – with details */

.page-content .faces-container.incomplete .face-block,
.page-content .faces-container.with-email .face-block {
  display: flex;
  padding: 1vw;
  align-items: center;
  text-align: left;
  border: 1px var(--primary-color) solid;
  background-color: var(--light-pink);
  border-radius: var(--border-radius);
}

.page-content .faces-container.incomplete .face-block {
  flex-direction: column;
  width: calc((var(--page-width) - (1.5vw * 2)) / 3); /* 3 blocks with 1.5vw gap */
  gap: 0.5vw;
}

.page-content .faces-container.with-email .face-block {
  width: 100%;
  gap: 1.5vw;
}

.page-content .faces-container.incomplete .face-block .face-icon,
.page-content .faces-container.with-email .face-block .face-icon {
  margin: 0;
}

.page-content .faces-container.incomplete .face-block .face-details,
.page-content .faces-container.with-email .face-block .face-details {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.page-content .faces-container.incomplete .face-block .face-details {
  width: 100%;
  align-items: center;
}

.page-content .faces-container.with-email .face-block .face-details {
  width: calc(var(--page-width) - 2vw - 1.5vw - var(--face-size) - 2px);
  /* page width - 2vw of paddings – 1.5vw gap - face size - 2px of borders */
}

.page-content .faces-container.incomplete .face-block .face-details h5 {
  margin: 0;
}

.page-content .faces-container.incomplete .face-block .face-details .progress {
  width: 70%;
}

.page-content .faces-container.with-email .face-block .face-details .face-stats {
  display: flex;
  gap: 2vw;
}

.page-content .faces-container.with-email .face-block .face-details .face-stats .stats-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1em;
  white-space: nowrap;
}

.page-content .faces-container.with-email .face-block .face-details .face-stats .stats-block > div:first-child {
  font-weight: bold;
}

.page-content .faces-container.with-email .face-block .face-details .face-stats .stats-block .shared-icon {
  display: flex;
  align-items: center;
}

.page-content .faces-container.with-email .face-block .face-details .face-stats .shared-icon svg {
  color: var(--error-color);
}

/* Progress bars */

.page-content .progress {
  border-radius: 50px;
  height: 1.75em;
}

.page-content .progress .progress-bar-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.page-content .progress.video-generation-progress {
  height: 2.5em;
  font-size: 1em;
}

.page-content .progress.video-generation-progress.empty .progress-bar {
  width: 100% !important;
  background-color: #e9ecef !important;
  color: #222;
}

.face-block .face-details .progress.face-progress {
  background-color: #fff;
  border: 1px var(--primary-color) solid;
}

.face-block .face-details .progress.face-progress .progress-bar {
  background-color: var(--primary-color) !important;
}

.face-block .face-details .progress.face-progress.empty .progress-bar {
  width: 100% !important;
  background-color: #fff !important;
  color: var(--primary-color);
}


/* KEYFRAMES */

@keyframes jumping-animation {
  0% {   
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulsing-animation {
  0% {   
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Animation for clips */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* MEDIA QUERIES */

@media only screen and (max-width: 500px) {
  /* General */

  .page-content .alert.text-with-icon svg {
    width: 50px;
  }

  /* Upload Dragger */

  .page-content .ant-upload-wrapper.shrunk .ant-upload-btn .ant-upload-drag-container {
    flex-direction: column;
    min-height: 150px;
    height: auto;
  }

  /* Face Upload */

  .page-content .faces-uploads-container {
    flex-direction: column;
  }
  
  .page-content .ant-upload-wrapper.face-upload .ant-upload-btn {
    width: 100%;
    padding: 10px 15px;
  }
  
  .page-content .ant-upload-wrapper.face-upload .ant-upload-drag-container {
    width: 100%;
    height: 100px;
  }

  .page-content .ant-upload-wrapper.face-upload .ant-upload-drag-container svg {
    height: 50px;
  }
  
  .page-content .ant-upload-wrapper.face-upload .ant-upload-drag-container .profile-image {
    width: 100px;
    height: 100px;
  }

  .page-content .names-list-group .name-list-text {
    min-width: 18vw;
  }
  

  /* Image Overlay */

  .img-overlay .loading-description {
    display: none;
  }

  /* Thumbanails Container & Faces Container */

  .page-content .thumbanails-container,
  .page-content .faces-container {
    gap: 3vw; /* gap needs to be 3vw so 3 blocks perfectly fit in the container */
  }

  .thumbanails-container .thumbanail-block .file-name {
    font-size: 0.8em;
  }

  .template-preview .thumbanails-container .thumbanail-block,
  .template-preview .thumbanails-container .thumbanail-block .thumbnail,
  .template-preview .thumbanails-container .thumbanail-block .img-overlay {
    width: var(--thumbnail-width);
    height: calc(var(--thumbnail-width) * 9 / 16);
  }

  .page-content .faces-container.incomplete,
  .page-content .faces-container.with-email {
    gap: 15px;
  }

  .page-content .faces-container.incomplete .face-block,
  .page-content .faces-container.with-email .face-block {
    flex-direction: column;
    width: 100%;
    padding: 15px;
    gap: 10px;
  }

  .page-content .faces-container.incomplete .face-block .face-details,
  .page-content .faces-container.with-email .face-block .face-details {
    width: 100%;
    gap: 10px;
    align-items: center;
  }
  
  .page-content .faces-container.with-email .face-block .face-details h5 {
    margin: 0;
  }

  /* Progress bars */

  .page-content .progress .progress-bar-label {
    gap: 10px;
  }
}