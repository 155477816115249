/* PAGE CONTENT */

.page-container .page-content {
  gap: 2vw;
}

/* Pink Background Box */

.page-container .pink-bg-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5vw 10%;
  border-radius: var(--border-radius);
  background-color: var(--light-pink);
  gap: 1vw;
}

.page-container .pink-bg-box .btn-container {
  display: flex;
  gap: 1vw;
}

.page-container .pink-bg-box .btn {
  width: 180px;
}

/* Email Verification Page */

.page-container.email-confirmation {
  height: calc(100vh - var(--header-height));
  text-align: center;
}

.page-container.email-confirmation .pink-bg-box img {
  width: 170px;
}

.page-container.email-confirmation .pink-bg-box .resend-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6vw;
}


/* KEYFRAMES */

@keyframes jumping-to-right-animation {
  0% {   
    transform: translateX(0);
  }
  50% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}


/* MEDIA QUERIES */

@media only screen and (max-width: 500px) {
  .page-container .pink-bg-box {
    padding: 20px;
    gap: 15px;
  }

  .page-container .pink-bg-box .btn-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
  }

  .page-container.email-confirmation .pink-bg-box .resend-container {
    gap: 10px;
  }
}