/* Form */

form,
form fieldset {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

form input.form-control {
  height: var(--form-el-height);
  padding: 1rem .75rem;
}

form .form-floating > :disabled ~ label::after {
  background-color: #e9ecef !important;
}

form .btn {
  width: 100%;
  height: var(--form-el-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

form .btn > * {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

form .btn > *.true {
  opacity: 1;
}

form .btn .text-with-icon {
  display: flex;
  align-items: center;
  gap: 0.2em !important;
}

form.inline-form,
form.inline-form fieldset {
  width: 100%;
  flex-direction: row;
}

form.inline-form .form-floating {
  width: 80%;
}

form.inline-form .inline-btn {
  width: 20%;
}

form.inline-form .inline-btn:hover .chevron-icon {
  animation: jumping-to-right-animation 1s linear infinite;
}

form .input-group.password-input-group .form-floating {
  width: 95%;
}

form .input-group.password-input-group .input-group-text {
  width: 5%;
  height: var(--form-el-height);
  justify-content: center;
}

form .input-group.password-input-group .form-text.text-muted {
  display: block;
  width: var(--page-width);
}

form .payment-methods-group .btn.btn-form {
  border: 1px solid #ced4da;
  color: #212529;
  gap: 0.5em;
}

form .payment-methods-group .btn.btn-form.selected {
  background-color: var(--light-pink);
  border: 1px var(--primary-color) solid;
  color: var(--primary-color);
}

form .payment-methods-group .btn.btn-form > * {
  position: relative;
  opacity: 1;
  transition: none;
}

form .payment-methods-group .btn.btn-form svg {
  width: 1.5em;
  height: 1.5em;
}

form .input-group .form-floating input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

form .input-group .cc-types {
  height: var(--form-el-height);
  font-size: 1.3em;
  gap: 0.5vw;
}

form .password-link {
  float: right;
}

form .invalid-feedback.show {
  display: block;
}

/* Card Box */

form .card-box input:focus {
  z-index: 1;
}

form .card-box .card-num-group > * {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

form .card-box .card-details-group > * {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

form .card-box.invalid .card-num-group > * {
  border-top-color: var(--error-color);
}

form .card-box.invalid .card-details-group > * {
  border-bottom-color: var(--error-color);
}

form .card-box.invalid > .input-group > *:first-child {
  border-left-color: var(--error-color);
}

form .card-box.invalid > .input-group > *:last-child {
  border-right-color: var(--error-color);
}


/* MEDIA QUERIES */

@media only screen and (max-width: 500px) {
  form,
  form fieldset {
    gap: 10px;
  }

  form.inline-form,
  form.inline-form fieldset {
    flex-direction: column;
    gap: 10px;
  }

  form.inline-form > *,
  form.inline-form fieldset > * {
    width: 100% !important;
  }

  form .input-group.password-input-group .form-floating {
    width: 85%;
  }
  
  form .input-group.password-input-group .input-group-text {
    width: 15%;
  }
}

