/* GENERAL */

:root {
  --header-height: 70px;
  --page-width: 70vw;
  --wide-page-width: 80vw;
  --size-nav-width: 20vw;
  --content-vertical-spacing: 24px;

  --thumbnail-width: calc((var(--page-width) - (1vw * 6)) / 7); /* 7 blocks with 1vw gap */
  --face-size: calc((var(--page-width) - (1.5vw * 5)) / 6); /* 6 blocks with 1.5vw gap */
  --form-el-height: calc(3.5rem + 2px);
  --border-radius: 0.375rem;

  /* colours */
  --primary-color: #f05a5b;
  --light-grey: #f5f5f5;
  --light-pink: #fdf1f4;
  --success-color: #198754;
  --error-color: #dc3545;

  --gradient-primary-color: #dc456b;
  --gradient-secondary-color: #e0674b;
  --gradient-to-bottom: linear-gradient(to bottom, var(--gradient-primary-color), var(--gradient-secondary-color));
  --gradient-to-right: linear-gradient(to right, var(--gradient-primary-color), var(--gradient-secondary-color));
}

html {
  scroll-padding-top: calc(var(--header-height) + 10px);
}

.btn,
.btn-imitator {
  width: 100px;
  box-shadow: none !important;
  border: none;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
}

.btn:hover {
  opacity: 0.8;
}

.btn.btn-primary {
  background-image: var(--gradient-to-right);
}

.btn.btn-clear:hover {
  background-image: var(--gradient-to-right);
  color: #fff;
  opacity: 1;
} 

.btn.btn-outline {
  border: 1px solid #aaa;
}

.btn.btn-outline:hover {
  border-color: var(--primary-color);
  opacity: 1;
}

.btn.btn-square-icon,
.btn.btn-square-icon:disabled {
  width: min-content;
  background-color: #eee;
}

.btn.btn-square-icon svg {
  color: var(--primary-color);
}

.btn.btn-square-icon:hover {
  opacity: 1;
  background-color: var(--primary-color);
}

.btn.btn-square-icon:hover svg {
  color: #fff;
}

.btn.btn-square-icon:disabled {
  opacity: 0.4;
}

.btn.btn-blue {
  background-color: #0ed9d5;
  color: #fff;
}

.form-check-input,
.form-check-input:focus {
  box-shadow: none !important;
  border-color: var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-control:disabled,
.form-control[readonly] {
  border-color: #ced4da;
}

input,
select {
  box-shadow: none !important;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-color);
  opacity: 0.6;
}

.success-icon {
  color: var(--success-color);
}

.custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-icon.circle {
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
}

.custom-icon.premium-icon.circle {
  background-color: var(--light-pink);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.custom-icon.premium-icon.circle svg {
  font-size: 0.8em;
}

.custom-icon.enabled-icon.circle {
  background-color: rgba(25, 135, 84, 0.2);
  color: var(--success-color);
}

.custom-icon.enabled-icon.circle svg {
  font-size: 1.1em;
}


/* HEADER */

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: var(--header-height);
  padding: 5px 3.5vw;
  background-image: var(--gradient-to-right);
  color: #fff;
  z-index: 5;
}

.header > * {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.header .menu-bar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 2em;
  color: #fff;
}

.header .menu-bar-icon:hover {
  background-image: none;
  background-color: #fff;
  color: var(--gradient-primary-color);;
}

/* SIDE NAV */

.side-nav {
  position: fixed;
  top: var(--header-height);
  left: 0;
  display: flex;
  flex-direction: column;
  width: var(--size-nav-width);
  height: calc(100vh - var(--header-height));
  padding: var(--content-vertical-spacing) 0;
  margin: 0 3vw 0 3.5vw;
  gap: var(--content-vertical-spacing);
  z-index: 4;
}

.side-nav .btn,
.side-nav .btn-imitator {
  width: 100% !important;
}

.side-nav .btn-clear {
  justify-content: space-between;
}

.side-nav .top-block {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.side-nav .top-block .user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  gap: 1em;
  font-size: 0.9em;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
  background-color: var(--light-pink);
  color: var(--primary-color);
}

.side-nav .top-block .user-block .user-email {
  font-weight: bold;
}

.side-nav .top-block .user-block .user-type {
  text-transform: capitalize;
}

.side-nav .top-block .workspace-btn .btn {
  height: var(--form-el-height);
}

.side-nav .top-block .workspace-btn .text-with-icon {
  justify-content: space-between;
  width: 100%;
}

.side-nav .top-block .workspace-btn .dropdown-menu {
  width: 100%;
  max-height: 55vh;
  overflow-y: scroll;
}

.side-nav .top-block .workspace-btn .dropdown-item {
  padding: 0.5rem 1rem;
}

.side-nav .top-block .workspace-btn .dropdown-item.active {
  background-color: var(--light-pink);
  color: #000;
}

.side-nav .top-block .workspace-btn .dropdown-item:hover {
  background-color: var(--primary-color);
  color: #fff;
  opacity: 1;
}

.side-nav .top-block .workspace-btn .dropdown-divider {
  opacity: 0.175;
}

.side-nav .top-block .workspace-btn .dropdown-toggle::after {
  display: none;
}

.side-nav .easy-links {
  overflow: auto;
  flex-grow: 1;
}

.side-nav .easy-links hr {
  margin: 0.375rem 0;
}

.side-nav .easy-links,
.side-nav .bottom-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.side-nav .bottom-block {
  margin-top: auto;
}

.side-nav .bottom-block hr {
  margin: 0;
}


/* APP */

.stepper #RFS-StepperContainer {
  padding: 0;
  padding-top: var(--content-vertical-spacing);
}

.stepper button:disabled,
.stepper button.non-clickable {
  pointer-events: none;
}

.page-container {
  width: var(--page-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: var(--header-height);
  text-align: left;
}

.app.with-side-nav .page-container.with-side-nav,
.app.with-side-nav .footer .button-group {
  margin-right: 3.5vw;
}

.note {
  font-size: 0.9em;
  font-style: italic;
}

.note.bold {
  font-style: normal;
  font-weight: bold;
}

/* Circle Icons Container */

.circle-icons-container {
  display: flex;
  gap: 0.2em;
}

.circle-icons-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.circle-icons-container .circle-icon {
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  color: var(--primary-color);
}

.circle-icons-container .circle-icon.clickable:hover {
  background-color: var(--primary-color);
  color: white;
  opacity: 1;
}

.circle-icons-container .circle-icon svg {
  width: 60%;
  height: 60%;
}

.circle-icons-container .circle-icon.big svg {
  width: 100%;
  height: 100%;
}


/* CONTENT HEADER */

.page-container .content-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1vw;
  padding: var(--content-vertical-spacing) 0;
}

.page-container .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  gap: 0.2em;
}

.popover.custom-popover  {
  border-color: var(--primary-color);
}

.popover.custom-popover .popover-header {
  background-image: var(--gradient-to-right);
  color: #fff;
  border-color: var(--primary-color);
}

.popover.custom-popover .popover-arrow::before,
.popover.custom-popover .popover-arrow::after,
.popover.custom-popover .popover-header::before {
  border-bottom-color: var(--primary-color);
}

.popover.custom-popover .popover-arrow::before{
  border-top-color: var(--primary-color);
}


/* PAGE CONTENT */

.page-container .page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  margin-bottom: calc(2.5rem + 48px);
}

.page-container.no-footer .page-content{
  margin-bottom: var(--content-vertical-spacing);
}

.page-container .page-content > * {
  width: 100%;
}

.page-content hr {
  margin: 0;
}

.page-content .page-block {
  display: flex;
  flex-direction: column;
  gap: 0.75vw;
}

.text-with-icon {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.text-with-icon.big-icon {
  gap: 0.75em;
}

.text-with-icon.big-icon svg {
  font-size: 1.5em;
}

/* Upload Info & Selling Point */

.page-content .upload-info,
.page-content .selling-point {
  padding: 1vw;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
}

.page-content .upload-info h5 {
  margin: 0;
}

.page-content .upload-info .text-with-icon {
  padding: 0.75em 1em;
  border-radius: var(--border-radius);
  background-color: var(--light-pink);
}

.page-content .upload-info .text-with-icon > div {
  width: fit-content;
}

.page-content .collapse-btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--form-el-height);
  gap: 0.75em;
}

.page-content .upload-info .collapse-btn {
  justify-content: center;
}

.page-content .collapse-btn svg {
  font-size: 1.5em;
}


/* SPINNER */

/* Big spinner that takes whole page */
.page-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.page-spinner .spinner-border {
  width: 5rem;
  height: 5rem;
  color: var(--primary-color);
}

/* Small spinner on the page */
.spinner-container  {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}


/* FOOTER */

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  height: calc(2.5rem + 48px + 10px);
  padding-bottom: var(--content-vertical-spacing);
  background-image: linear-gradient(to bottom, transparent 0%, #fff 30%);
  pointer-events: none;
  z-index: 3;
}

.footer .button-group {
  display: flex;
  justify-content: space-between;
  width: var(--page-width);
  margin: 0 auto;
}

.footer .button-group > div {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

.footer .button-group button {
  pointer-events: all;
  width: fit-content;
  min-width: 100px;
}


/* MODAL */

.modal-backdrop.show {
  opacity: 0.8;
}

.custom-modal .modal-header .btn-close {
  box-shadow: none;
}

.custom-modal .modal-body {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  text-align: justify;
}

.custom-modal .modal-body h5 {
 font-weight: 350;
}

.custom-modal .modal-body.text-center {
  padding: 2rem;
  align-items: center;
}

.custom-modal .modal-footer {
  border-top: none;
  padding-top: 0;
}

.custom-modal .modal-body .btn {
  width: 100%;
  height: var(--form-el-height);
}

.custom-modal .modal-btn-group {
  display: flex;
  justify-content: space-between;
  gap: 1.5vw;
}

.custom-modal .modal-btn-group .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 0.5em;
}

.custom-modal .modal-btn-group .btn.full {
  width: 100%;
}

.custom-modal .modal-btn-group .btn:hover svg {
  animation: jumping-animation 1s linear infinite;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 500px) {
  :root {
    --page-width: 90vw;
    --wide-page-width: 90vw;
    --size-nav-width: 70vw;
    --thumbnail-width: 28vw;
    --face-size: 28vw;
    /* 28vw – 3 blocks with 3vw gap in 90vw page*/
  }

  /* Side Nav */

  .side-nav {
    top: calc(var(--header-height) - 10px);
    left: 10px;
    height: 90vh;
    margin: 0;
    padding: 10px;
    gap: 15px;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    background-color: #fff;
    overflow: scroll;
  }

  .side-nav .top-block {
    gap: 10px;
  }

  /* Stepper */

  .stepper #RFS-Label {
    font-size: 0.8em !important;
  }

  /* Page Container */

  .app.with-side-nav .page-container.with-side-nav,
  .app.with-side-nav .footer .button-group {
    margin-left: auto;
  }

  .page-container .content-header {
    padding: 20px 0;
    gap: 10px;
  }

  .page-container .page-content {
    margin-bottom: 20px;
    gap: 20px;
  }

  .page-content .page-block {
    gap: 10px;
  }

  .page-content .upload-info,
  .page-content .upload-info .text-with-icon {
    padding: 10px;
  }

  /* Footer */

  .footer {
    position: static;
    width: 100%;
    height: auto;
    padding-bottom: 25px;
  }

  .footer .button-group {
    width: 100%;
  }

  .footer .button-group button {
    width: 40vw;
  }

  /* Modal */

  .custom-modal .modal-body {
    gap: 10px;
  }

  .custom-modal .modal-body.text-center {
    padding: 1rem;
  }
}