

.page-container .page-content .form-check {
  margin: 0;
}

.page-container .page-content .logo-preview-text .logo-preview {
  height: 1.2rem;
  margin-left: 0.5em;
}

.page-container .page-content .brand-logo {
  width: 100%;
  height: 384px;
  padding: 2vw;
  border: 1px solid #bbb;
  border-radius: var(--border-radius);
  align-self: baseline;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.page-container .page-content .brand-logo img {
  max-width: 90%;
  max-height: 250px;
}

.page-container .page-content .btns-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-container .page-content .btns-container .btn {
  width: fit-content;
}