/* GENERAL */

.page-container.pricing {
  width: var(--wide-page-width);
}

.page-container.pricing .page-content {
  text-align: center;
  gap: 5vw;
}

.page-container.pricing .page-content .page-block {
  /* text-align: center; */
  gap: 1vw;
}

.page-container.pricing hr {
  margin: 0.5rem 0;
}


/* CONTENT */

.page-container.pricing .content-header {
  align-items: center;
}

/* Subscription Table */

.page-container.pricing .pricing-table {
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
  border-collapse: separate;
}

.pricing-table > * > * > * {
  width: calc(100% / 5);
}

.pricing-table thead {
  background-image: var(--gradient-to-right);
  color: #fff;
}

.pricing-table thead tr th {
  padding: 2rem 0.5rem 1rem 0.5rem;
  font-size: 1.2em;
  font-weight: bold;
}

.pricing-table thead .price-row td {
  font-size: 1.2em;
  font-weight: bold;
}

.pricing-table thead .note-row td {
  font-style: italic;
}

.pricing-table thead .button-row td {
  padding: 1rem 0.5rem 2rem 0.5rem;
}

.pricing-table thead .button-row .btn {
  width: 80%;
  margin: auto;
  font-size: 0.9em;
}

.pricing-table tbody tr:not(:last-child) td {
  border-bottom: 1px solid var(--primary-color);
}

.pricing-table tbody tr:not(:first-child) td:not(:last-child) {
  border-right: 1px solid var(--primary-color);
}

.pricing-table tbody td {
  padding: 0.5rem;
  vertical-align: middle;
  color: #555;
}

.pricing-table tbody .features-heading td {
  font-weight: bold;
  background-color: #eee;
}

/* Events Container */

.page-container.pricing .events-container {
  display: flex;
  gap: 5vw;
}

.page-container.pricing .events-container .event-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23.33333333vw;
  height: min-content;
  padding: 2vw 1vw;
  gap: 1vw;
  border: 1px solid #bbb;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.page-container.pricing .events-container .event-block > * {
  width: 80%;
}

.page-container.pricing .events-container .event-block:hover,
.page-container.pricing .events-container .event-block.selected {
  border-color: var(--primary-color);
}

.event-block h5,
.event-block h6 {
  margin: 0;
}

.event-block .icon-container {
  display: flex;
  align-items: center;
}

.event-block .circle-icons-container.stack {
  position: relative;
  margin: auto;
}

.event-block .circle-icons-container.stack .circle-icon.main {
  width: 7em;
  height: 7em;
  color: var(--primary-color);
}

.event-block .circle-icons-container.stack .circle-icon.counter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2.5em;
  height: 2.5em;
  font-size: 1.2em;
  background-color: var(--primary-color);
  color: #fff;
  z-index: 1;
}

.event-block .event-price {
  font-size: 1.1em;;
  font-weight: bold;
}

.event-block .features-container svg {
  color: var(--success-color);
}

.event-block .features-container .text-with-icon {
  text-align: left;
}

.event-block .add-ons-container {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.event-block .add-ons-container .form-check {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  text-align: left;
}

.event-block .add-ons-container .form-check input {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.event-block .add-ons-container .form-check label {
  font-weight: bold;
}

.event-block .add-ons-container .form-check label .add-on-title {
  font-size: 0.8em;
  color: #555;
}


/* FOOTER */

.page-container.pricing .pricing-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  text-align: right;
}