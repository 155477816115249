/* Logo */

.logo {
  font-size: 0.75rem;
  color: var(--primary-color);
  text-decoration: none;
}

.logo:hover {
  opacity: 1;
}

/* Page Container */

.page-container.share-page {
  margin: 2vw auto;
  padding: 2vw 5vw;
  gap: 2vw;
  border-radius: var(--border-radius);
  text-align: center;
  background-color: var(--light-pink);
}

.page-container.share-page h2 {
  margin: 0;
}

.page-container.share-page .page-content {
  min-height: auto;
  margin: 0;
  overflow: initial;
}

.page-container.share-page .page-content img.share-page-logo {
  max-width: 50%;
  max-height: 100px;
  object-fit: contain;
}

.page-container.share-page .page-content .faces-container {
  justify-content: center;
}

.page-container.share-page .page-content .faces-container .face-block {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.page-container.share-page .page-content .faces-container a.face-block:hover {
  opacity: 1;
  transform: scale(1.15);
  text-decoration: underline;
}

.page-container.share-page .video-player {
  width: calc(var(--page-width) - 10vw);
  height: calc(9 * (var(--page-width) - 10vw) / 16);
}

.page-container.share-page .video-details {
  display: flex;
  justify-content: space-between;
}

/* Footer */

.share-footer {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 2vw auto;
  font-size: 0.75rem;
  color: #5e5e5e;
  text-align: center;
  gap: 0.5rem;
}

.share-footer .socials {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 10px;
}

.share-footer .socials img {
  width: 25px;
  height: 25px;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 500px) {
  /* Page Container */

  .page-container.share-page {
    width: var(--page-width);
    margin: 20px auto;
    padding: 5vw;
    gap: 15px;
  }

  .page-container.share-page .page-content .faces-container .face-block .face-icon {
    width: 24.6vw;
    height: 24.6vw;
  }

  .page-container.share-page .page-content .faces-container .face-block .face-name {
    width: 24.6vw;
  }

  .page-container.share-page .video-player {
    width: 80vw;
    height: calc(9 * 80vw / 16);
  }

  /* Footer */

  .share-footer {
    width: var(--page-width);
    margin: 20px auto;
  }
}