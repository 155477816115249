.reports-table tbody tr {
  border-top: 1px solid #bbb;
}

.reports-table th,
.reports-table td {
  padding: 1rem 0;
}

.reports-table th {
  font-weight: bold;
}

.reports-table .preview-col {
  width: 300px;
  padding-right: 100px;
}

.reports-table .preview-col img {
  width: 100%;
  border-radius: var(--border-radius);
}

.reports-table .action-icons-col {
  width: calc(120px + 1rem);
}

.reports-table .action-icons-col fieldset {
  display: flex;
  gap: 0.5rem;
}