:root {
  --banner-height: calc(var(--wide-page-width) * 9 / 34);
  --logo-size: 13vw;
}

.page-container.event {
  width: var(--wide-page-width);
  padding: 3vw 0;
}

.page-container.event hr {
  margin: 2rem 0;
}

/* Event Header */

.page-container.event .event-header {
  position: relative;
}

.page-container.event .event-header .banner {
  width: var(--wide-page-width);
  height: var(--banner-height);
  border-radius: var(--border-radius);
  object-fit: cover;
}

.page-container.event .event-header .logo {
  position: absolute;
  left: 1vw;
  top: calc(var(--banner-height) - var(--logo-size) / 2);
  width: var(--logo-size);
  height: var(--logo-size);
  border-radius: var(--border-radius);
}

.page-container.event .event-header .event-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(var(--logo-size) / 2);
  margin-left: calc(var(--logo-size) + 2vw);
}

.page-container.event .event-header .event-details .socials {
  display: flex;
  flex-direction: row;
  gap: 0.5vw;
}

.page-container.event .event-header .event-details .socials a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius);
  background-color: var(--light-pink);
}

.page-container.event .event-header .event-details .socials a:hover {
  background-color: var(--primary-color);
  color: var(--light-pink);
  opacity: 1;
}

.page-container.event .event-header .event-details .socials svg {
  width: 50%;
  height: 50%;
}

/* Page Content */

.page-container.event .page-content {
  width: var(--page-width);
  margin: auto;
}

.page-container.event .page-content form.check-in-form {
  gap: 2vw;
}

.page-container.event .page-content .page-block h5 {
  margin: 0;
}

/* Upload Dragger */

.page-container.event .ant-upload-wrapper .ant-upload-drag-container .profile-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.page-container.event .ant-upload-wrapper.shrunk .ant-upload-drag-container .upload-area-text {
  text-align: left;
}

.page-container.event .ant-upload-wrapper .ant-upload-drag-container .upload-area-text div:first-child {
  font-weight: bold;
}

/* Confirm Modal */

.custom-modal.confirm-modal .modal-body {
  font-size: 1.5em;
}

.custom-modal.upload-instructions .modal-body {
  padding: 2rem;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 500px) {
  :root {
    --logo-size: 20vw;
  }

  .page-container.event {
    padding: 25px 0;
  }

  .page-container.event hr {
    margin: 1rem 0;
  }

  /* Event Header */

  .page-container.event .event-header {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .page-container.event .event-header .event-details-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  .page-container.event .event-header .logo {
    position: static;
  }

  .page-container.event .event-header .event-details {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin: 0;
    gap: 10px;
  }

  .page-container.event .event-header .event-details .socials {
    gap: 10px;
  }

  .page-container.event .event-header .event-details .socials a {
    width: 35px;
    height: 35px;
  }

  /* Page Content */

  .page-container.event .page-content form.check-in-form {
    gap: 20px;
  }

  .page-content .ant-upload-wrapper .ant-upload-drag-container svg {
    height: 50px;
  }

  .page-container.event .ant-upload-wrapper .ant-upload-drag-container .profile-image {
    width: 100px;
    height: 100px;
  }

  .page-container.event .ant-upload-wrapper .ant-upload-drag-container .upload-area-text {
    text-align: center;
  }
}